<template>
  <lf-modal large :title="title" :close="() => emit('close')">
    <template #content>
      <form
        class="p-6 max-h-[70vh] overflow-y-auto space-y-6"
        data-cy="document-template-modal"
        @submit="submitForm"
      >
        <div class="text-headline font-semibold text-base">
          {{ $t("DOCUMENT_TEMPLATES.FORM.SET_TEMPLATE_DETAILS") }}
        </div>
        <div>
          <lf-input
            name="name"
            :placeholder="$t('DOCUMENT_TEMPLATES.FORM.DOCUMENT_TEMPLATE_NAME')"
            :value="formValues.name"
            data-cy="name-input"
          />
          <lf-textarea
            class="w-full border-gray-200 placeholder-gray-400 rounded text-xs text-gray-600"
            :placeholder="$t('COMMON.DESCRIPTION')"
            rows="3"
            name="description"
            :character-limit="150"
            :value="formValues.description ?? ''"
            data-cy="description-input"
          />
        </div>
        <loader :is-loading="loading" />
        <div class="space-y-8 min-h-[400px]">
          <div class="text-headline font-semibold text-base">
            {{ $t("DOCUMENT_TEMPLATES.CHOOSE_DOCUMENTS") }}
          </div>
          <div class="flex justify-between items-center">
            <div class="flex space-x-2 items-center">
              <search-input
                v-model="search"
                class="inline-block max-w-96"
                no-padding
                no-margin
                data-cy="search-input"
                name="stip-search"
                @update:model-value="search = $event"
              />
              <lf-checkbox-dropdown
                v-model="selectedTypes"
                v-tooltip="selectedTypes.join(', ')"
                class="activity-hub-filter-dropdown !my-0"
                button-classes="!w-32"
                name="types"
                :options="typeDropdownOptions"
                :placeholder="$t('COMMON.TYPE')"
                show-clear-filter
                enable-search
                width-full
                dropdown-width-full
                data-cy="type-dropdown"
              />
            </div>
            <div class="flex space-x-2 items-center">
              <outline-button
                data-cy="select-all-button"
                @click="() => (allAreSelected ? deselectAll() : selectAll())"
              >
                {{
                  allAreSelected
                    ? $t("COMMON.DESELECT_ALL")
                    : $t("COMMON.SELECT_ALL")
                }}
              </outline-button>
              <color-button
                data-cy="create-document-button"
                type="unselected"
                @click="showStipCreateModal = true"
              >
                {{ $t("DOCUMENT_TEMPLATES.FORM.ADD_NEW_DOCUMENT") }}
              </color-button>
            </div>
          </div>
          <div
            v-for="[categoryName, categoryStips] in Object.entries(
              categorizedStips
            )"
            :key="categoryName"
            class="text-headline space-y-4"
          >
            <div class="flex items-center font-bold space-x-2">
              <lf-checkbox
                :id="categoryName"
                :name="categoryName"
                value=""
                :data-cy="`category-${categoryName}-checkbox`"
                :model-value="categoryIsSelected(categoryName)"
                @update:model-value="handleSelectCategory(categoryName)"
              />
              <label class="cursor-pointer" :for="categoryName">
                {{ categoryName }}
              </label>
            </div>
            <div class="flex flex-wrap gap-4 pl-6">
              <button
                v-for="stip in categoryStips"
                v-tooltip="
                  stip.name.length > STIP_NAME_MAX_LENGTH
                    ? stip.name
                    : undefined
                "
                :key="stip.id"
                :data-cy="`stip-${stip.id}-button`"
                class="p-2 border border-gray-200 rounded-md text-headlineNew cursor-pointer transition-colors"
                :class="{
                  'border-primary bg-primary-background-active': stipIsSelected(
                    stip.id
                  )
                }"
                type="button"
                @click="toggleStip(stip.id)"
              >
                {{ truncateWithEllipsis(stip.name, STIP_NAME_MAX_LENGTH) }}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="border-t border-gray-200 py-6 flex justify-end">
        <modal-buttons
          class="bg-white"
          :on-cancel="() => emit('close')"
          :disabled="isSubmitting"
          :on-save="submitForm"
          no-vertical-padding
        />
      </div>
    </template>
  </lf-modal>
  <stip-create-modal
    v-if="showStipCreateModal"
    @close="showStipCreateModal = false"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { v4 as uuid } from "uuid";
import { useI18n } from "vue-i18n";
import { useDocumentTemplatesStore } from "@/stores/documentTemplates";
import { formatResponseErrors } from "@/helpers/vee-validate";
import { truncateWithEllipsis } from "@/helpers/formatting";
import StipCreateModal from "./StipCreateModal.vue";
import LfModal from "@/components/ui/Modal.vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import LfTextarea from "@/components/ui/inputs/LfTextarea.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import Loader from "@/components/ui/Loader.vue";
import LfCheckboxDropdown from "@/components/ui/inputs/LfCheckboxDropdown.vue";
import type {
  DocumentTemplate,
  DocumentTemplateDocument
} from "@/models/documentTemplates";
import type { HTTPError, StipsCollection } from "@/models/common";
import type { IStip } from "@/models/applications";

const STIP_NAME_MAX_LENGTH = 35;

const { templateId, mode } = defineProps<{
  mode: "create" | "edit";
  templateId?: string;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();
const { commit, getters, dispatch } = useStore();
const documentTemplatesStore = useDocumentTemplatesStore();

const CATEGORY_OTHER = t("COMMON.OTHER");

const {
  handleSubmit,
  isSubmitting,
  values: formValues,
  setErrors
} = useForm({
  initialValues: {
    name: "",
    description: ""
  }
});

const search = ref<string>("");
const selectedTypes = ref<string[]>([]);
const selectedStips = ref<DocumentTemplateDocument[]>([]);
const showStipCreateModal = ref(false);
const loading = ref(false);

const title = computed(() =>
  mode === "create"
    ? t("DOCUMENT_TEMPLATES.ADD_NEW_DOCUMENT_TEMPLATE")
    : t("DOCUMENT_TEMPLATES.EDIT_DOCUMENT_TEMPLATE")
);

const stips = computed<IStip[]>(() => getters["stips/stipsData"]?.data);

const fileTypes = computed<StipsCollection[]>(
  () => getters["options/fileTypes"]
);

const selectedStipIds = computed(() =>
  selectedStips.value.map((stip) => stip.stip_id)
);

const categorizedStips = computed(() => {
  if (!stips.value?.length) {
    return {};
  }

  const searchedStips = search.value
    ? stips.value.filter((stip) =>
        stip.name
          .toLocaleLowerCase()
          .includes(search.value.trim().toLocaleLowerCase())
      )
    : stips.value;

  const categorizedStips = searchedStips.reduce<Record<string, IStip[]>>(
    (acc, stip) => {
      const category =
        fileTypes.value.find((fileType) =>
          fileType.types.includes(stip.file_type)
        )?.name ?? CATEGORY_OTHER;

      (acc[category] ??= []).push(stip);
      return acc;
    },
    {}
  );

  if (selectedTypes.value.length) {
    return selectedTypes.value.reduce<Record<string, IStip[]>>((acc, type) => {
      acc[type] = categorizedStips[type];
      return acc;
    }, {});
  }

  return categorizedStips;
});

const typeDropdownOptions = computed(() => {
  if (!stips.value?.length) {
    return {};
  }

  const categorizedStips = stips.value.reduce<Record<string, string>>(
    (acc, stip) => {
      const category =
        fileTypes.value.find((fileType) =>
          fileType.types.includes(stip.file_type)
        )?.name ?? CATEGORY_OTHER;

      acc[category] = category;
      return acc;
    },
    {}
  );

  return categorizedStips;
});

const allAreSelected = computed(() => {
  return Object.values(categorizedStips.value).every((stips) =>
    stips.every((stip) => selectedStipIds.value.includes(stip.id))
  );
});

const submitForm = handleSubmit(async (values) => {
  const template: DocumentTemplate = {
    name: values.name,
    description: values.description,
    content: {
      documents: selectedStips.value
    }
  };

  try {
    if (mode === "edit") {
      template.id = templateId;
      await documentTemplatesStore.update(
        template as DocumentTemplate & { id: string }
      );
    } else if (mode === "create") {
      await documentTemplatesStore.create(template);
    }

    emit("close");
  } catch (error) {
    const formattedErrors = formatResponseErrors(
      (error as HTTPError).response.data.errors
    );
    setErrors(formattedErrors);
  }
});

const handleSelectCategory = (categoryName: string) => {
  const category = categorizedStips.value[categoryName];
  const categoryIds = category.map((stip) => stip.id);

  if (categoryIsSelected(categoryName)) {
    selectedStips.value = selectedStips.value.filter(
      (selectedStip) => !categoryIds.includes(selectedStip.stip_id)
    );

    return;
  }

  const unselectedCategoryIds = categoryIds.filter(
    (stipId) => !selectedStipIds.value.includes(stipId)
  );

  selectedStips.value.push(
    ...unselectedCategoryIds.map((id) => ({ id: uuid(), stip_id: id }))
  );
};

const categoryIsSelected = (categoryName: string) => {
  const category = categorizedStips.value[categoryName];

  return category.every((stip) => selectedStipIds.value.includes(stip.id));
};

const toggleStip = (id: number) => {
  if (stipIsSelected(id)) {
    selectedStips.value = selectedStips.value.filter(
      (selectedId) => id !== selectedId.stip_id
    );
    return;
  }

  selectedStips.value.push({ id: uuid(), stip_id: id });
};

const selectAll = () => {
  const unselectedStips = stips.value.filter(
    (stip) => !selectedStipIds.value.includes(stip.id)
  );

  selectedStips.value.push(
    ...unselectedStips.map((stip) => ({ id: uuid(), stip_id: stip.id }))
  );
};

const deselectAll = () => {
  selectedStips.value = [];
};

const stipIsSelected = (id: number) =>
  selectedStips.value.some((selectedStip) => selectedStip.stip_id === id);

onMounted(async () => {
  try {
    loading.value = true;

    await dispatch("stips/getStips", { params: {} });

    if (!templateId) {
      return;
    }

    const template = await documentTemplatesStore.getById(templateId);

    if (!template?.content?.documents) {
      return;
    }

    formValues.name = template.name;
    formValues.description = template.description ?? "";

    selectedStips.value = template.content.documents.map((document) => ({
      id: document.id,
      stip_id: document.stip_id
    }));
  } catch (error) {
    //
  } finally {
    loading.value = false;
  }
});

onUnmounted(() => commit("stips/unsetStips"));
</script>

<style scoped>
.activity-hub-filter-dropdown :deep() {
  > button {
    @apply bg-grey-hover border-grey-hover;
    & > span {
      @apply text-headline ml-0;
    }
    & svg.rotate-180 {
      @apply text-primary;
    }
  }
}
</style>
